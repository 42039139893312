import { ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import type { FunctionComponent } from "react";
import { useEffect, useRef, useState } from "react";
import type { SwipeCallback, SwipeableHandlers } from "react-swipeable";
import { useSwipeable } from "react-swipeable";
import type { ProductFlatten } from "~/types";
import { debounce } from "~/utils/useDebounce";
import ProductCard from "./productCard.component";

const Carousel: FunctionComponent<{ category?: string; title: string; link?: string }> = ({
  category,
  title,
  link,
}) => {
  const [products, setProducts] = useState<ProductFlatten[]>([]);
  const scrollWidth = useRef<number>(0);
  const itemWidth = useRef<number>(0);
  const carousel = useRef<HTMLDivElement>(null);
  const carouselItem = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [slides, setSlides] = useState(1);
  const [itemsPerSlide, setItemsPerSlide] = useState(1);
  const [currentItem, setCurrentItem] = useState(0);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const queryParams = category ? `?category=${category}` : "";
        const response = await fetch(`/api/products${queryParams}`);
        const data: { products: ProductFlatten[] } = await response.json();
        setProducts(data.products);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    fetchProducts();
  }, [category]);

  const updateIndex = (newIndex: number) => {
    let updatedIndex = newIndex;
    if (newIndex < 0) {
      updatedIndex = slides - 1;
    } else if (newIndex >= slides) {
      updatedIndex = 0;
    }
    setActiveIndex(updatedIndex);
    setCurrentItem(updatedIndex * itemsPerSlide);
  };

  const forward: SwipeCallback = () => {
    updateIndex(activeIndex + 1);
  };

  const backward: SwipeCallback = () => {
    updateIndex(activeIndex - 1);
  };

  useEffect(() => {
    const calculateSlides = () => {
      scrollWidth.current = carousel.current ? carousel.current.clientWidth : 0;
      itemWidth.current = carouselItem.current ? carouselItem.current.clientWidth : 0;
      const newSlides = Math.round((itemWidth.current * products.length) / scrollWidth.current);
      setItemsPerSlide(products.length / newSlides);
      setSlides(newSlides);
    };
    calculateSlides();
    const debouncedCalculateSlides = debounce(calculateSlides);
    window.addEventListener("resize", debouncedCalculateSlides);
  }, [products.length]);

  useEffect(() => {
    const newIndex = Math.floor(currentItem / itemsPerSlide);
    setActiveIndex(newIndex);
  }, [currentItem, itemsPerSlide]);

  const handlers: SwipeableHandlers = useSwipeable({
    onSwipedLeft: forward,
    onSwipedRight: backward,
  });

  // Avoid showing the carousel if there are no products
  if (!products || products.length === 0) return <></>;

  const indicators = [];

  // Use a regular for loop to create the indicator elements
  for (let index = 0; index < slides; index++) {
    indicators.push(
      <div
        key={index}
        className={`${
          index === activeIndex ? "bg-white sm:bg-admirerBlack-300" : "bg-admirerBlack-300 sm:bg-admirerBlack-700"
        } float-left m-1 h-2 w-4 rounded-full hover:bg-admirerBlue`}
        onClick={() => updateIndex(index)}
      />,
    );
  }

  return (
    <div {...handlers} className="carousel mboverflow-hidden relative pb-10">
      <div className="top left absolute  h-full w-full">
        <ChevronLeftIcon
          className="absolute top-[68%] z-10 m-1 ml-8 h-6 w-6 flex-shrink-0 cursor-pointer rounded-full text-white drop-shadow-md hover:text-admirerBlue dark:text-white sm:ml-0 md:top-1/2 md:text-admirerBlack-900"
          aria-hidden="true"
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        />
        <div className="indicators absolute bottom-0 left-0 right-0 z-10 mx-auto h-8 w-fit  pb-0">{indicators}</div>
        <ChevronRightIcon
          className="absolute right-0 top-[68%] z-10 m-1 mr-8 h-6 w-6 flex-shrink-0 cursor-pointer self-center rounded-full text-white drop-shadow-md hover:text-admirerBlue dark:text-white sm:mr-0 md:top-1/2 md:text-admirerBlack-900"
          aria-hidden="true"
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        />
      </div>
      <div className="flex w-full justify-between px-1 pb-1">
        <h1 className="text-shadow-2xl pl-1 pt-8 text-xl font-bold leading-5 text-admirerBlack dark:text-white sm:pt-8 sm:text-2xl">
          {title}
        </h1>
        {link && (
          <a className="z-10 flex items-end text-admirerBlue" href={link}>
            <h1
              data-analytics="carousel-see-more"
              className="text-shadow-2xl pl-1 pt-4 text-xs font-bold leading-5 sm:pt-8 sm:text-xl"
            >
              See more
            </h1>

            <ArrowRightIcon className="h-4 pb-1 pl-1 sm:h-6" />
          </a>
        )}
      </div>
      <div className="overflow-hidden sm:mx-12">
        <div
          ref={carousel}
          className="scroll-smooth whitespace-nowrap transition-transform"
          style={{ transform: `translateX(-${currentItem * itemWidth.current}px)` }}
        >
          {products.map((product, index) => {
            return (
              <div
                key={product.id}
                data-analytics={`carousel-item-${product.title}`}
                ref={carouselItem}
                className="box-border inline-flex w-full snap-start items-center justify-center sm:w-1/2 lg:w-1/3 xl:w-1/4"
              >
                <ProductCard product={product} type="carousel" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
